@import "../styles/colors";
@import "../styles/transitions";

.dialog {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0.9rem;
  background: transparentize($black, 0.5);

  header {
    margin-bottom: 0.25rem;
  }

  h2 {
    padding: 0;
    margin: 0 0 0 0.5rem;
    font-size: 1.125rem;
  }

  .dialog-window {
    max-width: 80%;
    border-radius: 0.25rem;
    background: $white;
  }

  .dialog-content {
    padding: 1rem;

    header .icon {
      width: 2em;
      height: 2em;
    }

    p {
      margin-bottom: 0;
    }
  }

  .dialog-buttons button {
    padding: 0.5rem 0;
    border-top: 1px solid $gray-lightest;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    background: transparent;
    transition: color $fast-transition $easing, border $fast-transition $easing,
      background $fast-transition $easing;

    &:first-child {
      border-right: 1px solid $gray-lightest;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-right-radius: 0.25rem;
    }

    &:focus,
    &:hover {
      background: $gray-lightest;
    }

    &:active {
      color: $white;
      border-color: $primary;
      background: $primary;
    }
  }
}
