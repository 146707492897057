@import "../styles/colors";
@import "../styles/transitions";

.spinner {
  position: relative;
  padding: 1rem;
  border-radius: 0.25rem;
  background: $gray-lightest;
  transform: scale(0);
  animation-delay: $normal-transition;
  animation-duration: $normal-transition;
  animation-fill-mode: forwards;
  animation-name: fade-in-spinner;
  animation-timing-function: $easing;

  &.is-delayed {
    animation-delay: $slow-transition;
  }

  svg {
    width: 100%;
    height: 100%;

    circle {
      stroke: $gray-dark;
    }

    .one,
    .two {
      fill: $gray-lightest;
    }
  }

  &.is-light {
    background: $white;

    svg {
      circle {
        stroke: $gray-light;
      }

      .one,
      .two {
        fill: $white;
      }
    }
  }

  @keyframes fade-in-spinner {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
