@import "../styles/colors";
@import "../styles/columns";
@import "../styles/ratio";
@import "../styles/transitions";

.home {
  .home-info-news {
    position: relative;
    margin: 0.5rem 0;
    overflow: auto;
  }

  .home-info-buttons {
    position: relative;
    z-index: 1;
    margin-top: 0.5rem;
    margin-bottom: -2.5rem;
    height: 2rem;

    .drop-area,
    .button-like-file-select {
      width: 16rem;
      border: 1px solid $gray-lightest;
      background: white;
    }

    .drop-area {
      margin-right: 0.5rem;

      &:after {
        top: 0.125rem;
        right: 0.125rem;
        bottom: 0.125rem;
        left: 0.125rem;
        border-width: 1px;
        border-color: $gray-medium;
      }
    }

    .button-like-file-select {
      margin-right: 0.5rem;
      transition: color $fast-transition $easing,
        border-color $fast-transition $easing,
        background $fast-transition $easing;

      &:hover {
        border-color: darken($blue-light, 5%);
        background: $blue-light;
      }
    }
  }

  .hga-h3 {
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }

  .example-1,
  .example-2,
  .example-3,
  .example-4 {
    position: relative;
    border-radius: 0.25rem;
    border: 1px solid $gray-lightest;
  }

  .example-1 {
    @include ratio(0.75);
  }

  .example-2 {
    @include ratio(0.55);
  }

  [class^="reference-"] {
    margin-right: 0.25rem;
  }

  /*
   * -----------------------------------------------------------------------------
   * Media Queries
   * -----------------------------------------------------------------------------
   */

  /* 400px */
  @media screen and (max-width: 30em) {
    .home-content > section {
      padding-top: 0.75rem;
    }

    .home-info-buttons {
      .drop-area,
      .button-like-file-select {
        width: 12rem;
      }
    }
  }
}
