@import "../styles/colors";
@import "../styles/ratio";

.plugin-list {
  & > li {
    position: relative;
    width: 50%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }

  .plugin-title {
    display: block;
    margin-bottom: -1px;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    background: $gray-lightest;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: none;
  }

  .plugin-figure {
    @include ratio(0.5);

    border: 1px solid $gray-lightest;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: $white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .one-line {
    margin: 0.125em 0;

    label {
      min-width: 3em;
      content: "Demo";
      font-size: 0.8em;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 0.5rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/*
 * -----------------------------------------------------------------------------
 * Media Queries
 * -----------------------------------------------------------------------------
 */

/* 960px */
@media screen and (min-width: 60em) {
  .example-list > li {
    width: 33.33%;
    margin-bottom: 1rem;
    padding: 1rem;

    &.two-columns {
      width: 66.66%;
    }
  }
}

/* 1600px */
@media screen and (min-width: 100em) {
  .example-list > li {
    width: 25%;

    &.two-columns {
      width: 50%;
    }
  }
}

/* 1920px */
@media screen and (min-width: 120em) {
  .example-list > li {
    width: 20%;

    &.two-columns {
      width: 40%;
    }
  }
}
