@import "../styles/colors";
@import "../styles/transitions";

.button-icon {
  height: 1.5em;
  margin: 0;
  padding: 0;
  border: 1px solid $gray-lightest-rgba;
  border-radius: 0.25rem;
  background: transparent;
  transition: color $normal-transition $easing,
    border-color $normal-transition $easing,
    background $normal-transition $easing;
  white-space: nowrap;

  .icon {
    width: 1em;
    height: 1em;
    margin-right: 0.25em;
  }

  &.button-icon-only {
    width: 1.5em;

    .icon {
      margin-right: 0;
    }
  }
}
