.column-1 {
  width: 100%;
}

.column-1-2 {
  width: 50%;
}

.column-1-3 {
  width: 33.33%;
}

.column-2-3 {
  width: 66.66%;
}

.column-1-4 {
  width: 25%;
}

.column-3-4 {
  width: 75%;
}

.column-1-5 {
  width: 20%;
}

.column-2-5 {
  width: 40%;
}

.column-3-5 {
  width: 60%;
}

.column-4-5 {
  width: 80%;
}
