@mixin underline($color, $bottom, $height) {
  position: relative;

  &::after {
    position: absolute;
    display: block;
    content: "";
    bottom: $bottom;
    left: 0;
    right: 0;
    height: $height;
    background: $color;
    transform: scale(0, 1);
    opacity: 0;
    transition: transform $normal-transition $easing,
      opacity $fast-transition $easing;
  }

  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
}
