@import "../styles/colors";

.error-msg {
  position: relative;
  padding: 1rem;
  border-radius: 0.25rem;
  color: $error-text;
  background: $error-bg;

  .icon {
    width: 3em;
    height: 3em;
  }

  p {
    margin: 1em 0 0 0;
  }
}
