@import "../styles/colors";

.app {
  .indicator {
    position: absolute;
    top: -1.8rem;
    left: 50%;
    width: 16rem;
    margin-left: -8rem;
    font-size: 0.9em;
    line-height: 1.5em;
    text-align: center;
    border-radius: 0.25rem 0.25rem 0 0;
    background: $gray-lightest;
  }
}
