@import "../styles/colors";
@import "../styles/transitions";

.top-bar-drop-down {
  .drop-down-content {
    display: none;
    z-index: 20;
    width: 15rem;
    font-size: 1rem;
    line-height: 2rem;
    border-radius: 0 0 0.25rem 0.25rem;
    background: transparentize($gray-lightest, 0.05);
    box-shadow: 0 1px 2px 0 $gray-light-rgba;

    button,
    input {
      border: 0;
      transition: color $fast-transition $easing,
        background $fast-transition $easing, box-shadow $fast-transition $easing;

      &:focus {
        outline: none;
        box-shadow: 0 0 3px 1px $blue-highlight;
      }
    }
  }

  &.drop-down-is-open > .drop-down-content {
    display: flex;
  }

  .drop-down-trigger {
    transition: background $fast-transition $easing,
      color $fast-transition $easing;
  }

  &.drop-down-is-open .drop-down-trigger {
    color: black;
    background: linear-gradient($gray-light, $gray-lightest);
  }

  .drop-down-form {
    input,
    button {
      margin: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      transition: opacity $fast-transition $easing;

      &:first-child {
        margin-top: 0.5rem;
      }

      &:last-child {
        margin-bottom: 0.5rem;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    button {
      color: $gray-darker;
      background: $gray-light;

      &:focus,
      &:hover,
      &.is-active {
        color: $white;
        background: $primary;
      }
    }

    .error,
    .warning {
      margin-bottom: 0.25rem;
      padding: 0.25rem 0.5rem;
      color: $red-dark;
      font-size: 0.9em;
      line-height: 1.5em;
      background: $red-light;

      .icon {
        margin-right: 0.25rem;
        width: 1em;
        height: 1em;
      }
    }

    .error {
      color: $error-text;
      background: $error-bg;
    }

    .warning {
      color: $warning-text;
      background: $warning-bg;
    }
  }

  .menu-field {
    padding: 0 0.5rem;

    &:first-child {
      margin-top: 0.25rem;
    }

    &:last-child {
      margin-bottom: 0.25rem;
    }
  }

  .menu-button {
    text-align: left;
    border-radius: 0;
    background: transparent;

    &:hover {
      color: $white;
      background: $primary;
    }
  }

  .menu-button-like-link {
    margin: 0;
    padding: 0 0.5rem;
    text-align: left;
    border-radius: 0;
    background: transparent;

    &:after {
      display: none;
    }

    &:hover {
      color: $white;
      background: $primary;
      box-shadow: none;
    }
  }

  li:last-child .menu-button-like-link {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .menu-separator {
    height: 1px;
    margin: 0.25rem 0;
    background: $gray-lighter;
  }

  .menu-text-label {
    color: $gray-medium;
    font-size: 0.8em;
    line-height: 1.25em;
    text-transform: uppercase;
  }
}
