@import "../styles/colors";
@import "../styles/transitions";

.button-like-link {
  position: relative;
  border: 0;
  border-radius: 0.25rem;
  background: $gray-lighter;
  transition: color $normal-transition $easing,
    background $normal-transition $easing;

  &:hover {
    background-image: linear-gradient(180deg, transparent, $gray-lightest-rgba);
  }

  > a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    box-shadow: none;

    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}
