@import "../styles/colors";
@import "../styles/transitions";

/**
 * Copyright 2014, Call Me Nick
 *
 * https://github.com/callmenick/Animating-Hamburger-Icons
 *
 * Adjusted by Fritz Lekschas
 */

$button-width: 3rem; // The width of the button area
$button-height: 3rem; // The height of the button area
$bar-thickness: 0.135rem; // The thickness of the button bars
$button-pad: 0.5rem; // The left/right padding between button area and bars.
$button-bar-space: 0.375rem; // The spacing between button bars

.hamburger-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  .hamburger,
  .hamburger-bg {
    display: block;
    width: $button-width;
    height: $button-height;
  }

  .hamburger {
    position: relative;
    z-index: 1;
    transition: background $fast-transition;

    .hamburger-bar-top,
    .hamburger-bar-middle,
    .hamburger-bar-bottom, {
      top: ($button-height / 2) - ($bar-thickness / 2);
      left: $button-pad;
      right: $button-pad;
      position: absolute;
      display: block;
      height: $bar-thickness;
      background: $gray-dark;
    }

    .hamburger-bar-top {
      transform: translate(0, -$bar-thickness - $button-bar-space);
    }

    .hamburger-bar-bottom {
      transform: translate(0, $bar-thickness + $button-bar-space);
    }

    &.hamburger-to-x.is-active .hamburger-bar-middle {
      animation-name: hamburger-bar-middle;
      animation-timing-function: $easing;
      animation-duration: $slow-transition;
      animation-fill-mode: forwards;
    }

    &.hamburger-to-x.is-active  .hamburger-bar-top {
      animation-name: hamburger-bar-top;
      animation-timing-function: $easing;
      animation-duration: $slow-transition;
      animation-fill-mode: forwards;
    }

    &.hamburger-to-x.is-active  .hamburger-bar-bottom {
      animation-name: hamburger-bar-bottom;
      animation-timing-function: $easing;
      animation-duration: $slow-transition;
      animation-fill-mode: forwards;
    }
  }

  .hamburger-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    opacity: 0;
    background: $primary;
    transition: opacity $fast-transition $easing;
  }

  .hamburger.is-active + .hamburger-bg {
    animation-name: hamburger-bg;
    animation-timing-function: $easing;
    animation-duration: $slow-transition;
    animation-fill-mode: forwards;
  }

  @keyframes hamburger-bar-middle {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes hamburger-bar-top {
    0% {
      transform: translate(0, -$bar-thickness - $button-bar-space);
    }
    20% {
      transform: translate(0, 0);
    }
    30% {
      transform: rotate(0deg);
    }
    50% {
      background: $gray-dark;
    }
    80% {
      transform: rotate(45deg);
    }
    100% {
      background: $white;
      transform: rotate(45deg);
    }
  }

  @keyframes hamburger-bar-bottom {
    0% {
      transform: translate(0, $bar-thickness + $button-bar-space);
    }
    20% {
      transform: translate(0, 0);
    }
    30% {
      transform: rotate(0deg);
    }
    50% {
      background: $gray-dark;
    }
    80% {
      transform: rotate(-45deg);
    }
    100% {
      background: $white;
      transform: rotate(-45deg);
    }
  }

  @keyframes hamburger-bg {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
