@import "../styles/colors";
@import "../styles/ratio";
@import "../styles/transitions";

.about {
  .about-resource-list time {
    margin-left: 0.25em;
  }

  .about-author-list li {
    margin: 0.25rem 0;
    // padding-left: 0.25rem;

    &:after {
      content: ",";
      margin-left: 0.05rem;
      margin-right: 0.5rem;
    }

    &:last-child:after {
      display: none;
    }

    &:nth-last-child(2):after {
      content: ", and";
      margin-right: 0.33rem;
    }

    > * {
      display: inline-block;
    }

    &.no-comma:after {
      content: "";
    }
  }

  .iconized p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .extra-link {
    margin-left: 0.25rem;
    color: $gray-medium;

    &:after {
      content: "]";
    }
    &:before {
      content: "[";
    }
  }

  .video {
    @include ratio(.5625);
    max-width: 35rem;

    .placeholder-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0.25rem;
      background-image: url('../images/scipy-preview.jpg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      box-shadow: inset 0 0 0 1px $gray-lighter-rgba;
      transition: box-shadow $fast-transition $easing,
                  transform $fast-transition $easing;

      &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 2px $primary;

        .icon {
          opacity: 1;

          .primary {
            fill: $white;
          }

          .secondary {
            fill: $primary;
          }
        }
      }

      .icon {
        width: 8rem;
        height: 8rem;
        opacity: 0.92;
        transition: opacity $fast-transition $easing;

        .primary {
          fill: $white;
        }
        .secondary {
          transition: fill $fast-transition $easing;
          fill: $gray-medium;
        }
      }
    }
  }
}
