@import "../styles/colors";

.not-found {
  .not-found-header {
    h2 {
      margin-top: 1rem;
    }

    .icon-wrapper {
      width: 8em;
      height: 8em;
      padding: 1em;
      color: $gray-darker;
      border-radius: 0.25rem;
      background: $gray-lightest;

      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  em {
    color: $gray-medium;
  }
}
