.flex-c {
  display: flex;
}

.flex-v {
  flex-direction: column;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-c {
  justify-content: center;
}

.flex-jc-e {
  justify-content: flex-end;
}

.flex-w-w {
  flex-wrap: wrap;
}

.flex-a-s {
  align-items: stretch;
}

.flex-a-c {
  align-items: center;
}

.flex-g-1 {
  flex: 1;
}
