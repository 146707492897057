@import "../styles/colors";
@import "../styles/transitions";

.drop-notifier {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  color: $white;
  font-size: 4em;
  font-weight: bold;
  text-transform: uppercase;
  background: transparentize(lighten($primary, 20%), 0.2);
  opacity: 0;
  transition: opacity $normal-transition $easing;

  &.is-active {
    height: 100vh;
    opacity: 1;
    animation: pulsePrimary 1.5s infinite;
    cursor: grabbing !important;

    span {
      transform: scale(1);
      animation: pulseText 1.5s infinite;
    }
  }

  span {
    transform: scale(0);
    transition: transform $normal-transition $easing;
  }

  .drop-layer {
    position: absolute;
    z-index: 100;
  }

  @keyframes pulsePrimary {
    0% {
      background: transparentize(lighten($primary, 20%), 0.2);
    }
    60% {
      background: transparentize(lighten($primary, 20%), 0.05);
    }
    100% {
      background: transparentize(lighten($primary, 20%), 0.2);
    }
  }

  @keyframes pulseText {
    0% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
