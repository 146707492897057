.share-view-config-url input {
  margin-left: 0.25rem;
}

.share-view-config-url input.pr {
  margin-right: 0.25rem;
}

.share-view-config-url-label {
  width: 2.5rem;
}

.share-view-config-url .button-icon .icon {
  margin: 0 0.25em;
}
