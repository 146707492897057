.m-b-0-25 { margin-bottom: 0.25rem; }
.m-l-0-25 { margin-left: 0.25rem; }
.m-r-0-25 { margin-right: 0.25rem; }
.m-t-0-25 { margin-top: 0.25rem; }

.m-b-0-5 { margin-bottom: 0.5rem; }
.m-l-0-5 { margin-left: 0.5rem; }
.m-r-0-5 { margin-right: 0.5rem; }
.m-t-0-5 { margin-top: 0.5rem; }

.m-b-1 { margin-bottom: 1rem; }
.m-l-1 { margin-left: 1rem; }
.m-r-1 { margin-right: 1rem; }
.m-t-1 { margin-top: 1rem; }

.m-b-2 { margin-bottom: 2rem; }
.m-l-2 { margin-left: 2rem; }
.m-r-2 { margin-right: 2rem; }
.m-t-2 { margin-top: 2rem; }

.p-b-0-25 { padding-bottom: 0.25rem; }
.p-l-0-25 { padding-left: 0.25rem; }
.p-r-0-25 { padding-right: 0.25rem; }
.p-t-0-25 { padding-top: 0.25rem; }

.p-b-0-5 { padding-bottom: 0.5rem; }
.p-l-0-5 { padding-left: 0.5rem; }
.p-r-0-5 { padding-right: 0.5rem; }
.p-t-0-5 { padding-top: 0.5rem; }

.p-b-1 { padding-bottom: 1rem; }
.p-l-1 { padding-left: 1rem; }
.p-r-1 { padding-right: 1rem; }
.p-t-1 { padding-top: 1rem; }

.p-b-1-5 { padding-bottom: 1.5rem; }
.p-l-1-5 { padding-left: 1.5rem; }
.p-r-1-5 { padding-right: 1.5rem; }
.p-t-1-5 { padding-top: 1.5rem; }

.p-b-2 { padding-bottom: 2rem; }
.p-l-2 { padding-left: 2rem; }
.p-r-2 { padding-right: 2rem; }
.p-t-2 { padding-top: 2rem; }
