@import "../styles/colors";

.sub-top-bottom-bar-buttons {
  li {
    margin: 0 0.125rem;
  }

  &:first-child li:first-child {
    margin-left: 0;
  }

  &:last-child li:last-child {
    margin-right: 0;
  }

  button {
    &:hover {
      color: $gray-darker;
      border-color: $gray-lighter;
      background: $gray-lighter;
    }

    &:focus {
      color: $gray-darker;
      border-color: $gray-lighter;
      background: $gray-lighter;
      outline: none;
      box-shadow: 0 0 3px 1px transparentize($primary, 0.33);
    }

    &:active {
      color: $white;
      border-color: $black;
      background: $black;
      outline: none;
    }

    &.is-active {
      color: $white;
      border: 0;
      background: $primary;
    }
  }
}
