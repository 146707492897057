@import "../styles/colors";
@import "../styles/transitions";

.right-bar {
  position: absolute;
  top: 3rem;
  right: 0;
  bottom: 0;
  transition: width $fast-transition $easing;

  .right-bar-toggler {
    position: absolute;
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 2rem;
    color: $gray-medium;
    border: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: $gray-lighter;
    box-shadow: -1px 0 0 0 $gray-lighter-rgba;

    &:hover {
      color: $gray-darker;
      background: $gray-light;
      box-shadow: -1px 0 0 0 $gray-light-rgba;
    }

    &:focus {
      color: $gray-darker;
      border-color: $gray-lighter;
      background: $gray-lighter;
      outline: none;
      box-shadow: 0 0 3px 1px transparentize($primary, 0.33);
    }

    &:active {
      color: $white;
      border-color: $black;
      background: $black;
      outline: none;
    }

    .icon {
      width: 0.75rem;
    }
  }
}

.content-wrapper-bottom-bar > .right-bar {
  margin-bottom: 1.5rem;
}
