@import "../styles/colors";

.button-like-file-select {
  padding: 0 0.5rem;
  border-radius: 0.25rem;

  &:active,
  &:hover {
    color: $primary;
    cursor: pointer;
    background-image: linear-gradient(180deg, transparent, $gray-lightest-rgba);
  }

  input {
    display: none;
  }

  .icon {
    width: 1em;
    height: 1em;
  }
}
